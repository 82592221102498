import {ColumnLayout, FormField, Input, Select, SelectProps} from "@amzn/awsui-components-react";
import React, {useState} from "react";
import AtochaWorkflowContent from "../workflowUtils/AtochaWorkflowContent";
import PmetworkflowContent from "../workflowUtils/PmetWorkflowContent";
import RedfortWorkflowContent from "../workflowUtils/RedfortWorkflowContent";

export default function SubscribableWorkflowPage() {
    const [
        selectedSIMFolder,
        setSelectedSIMFolder
    ] = useState<SelectProps.Option>({label: "PAMS", value: "PAMS"});

    const [
        selectedWorkflowType,
        setSelectedWorkflowType
    ] = useState<SelectProps.Option>({});

    return (
        <ColumnLayout borders="horizontal">
            <h2>Create Configurable Workflows</h2>
            <ColumnLayout columns={4}>
                <div>
                    <FormField
                        label="Choose SIM Folder"
                    >
                        <Select
                            selectedOption={selectedSIMFolder}
                            onChange={({detail}) =>
                                setSelectedSIMFolder(detail.selectedOption)
                            }
                            options={[
                                {label: "PAMS", value: "PAMS"},
                                {label: "PIDS", value: "PIDS"},
                            ]}
                            selectedAriaLabel="Selected"
                        />
                    </FormField>
                </div>
                <div>
                    <FormField
                        label="Choose Workflow Type"
                    >
                        <Select
                            selectedOption={selectedWorkflowType}
                            onChange={({detail}) =>
                                setSelectedWorkflowType(detail.selectedOption)
                            }
                            // Value in this dropdown should be Workflow type constant in Workflow_Trigger_Mapping_Store table
                            options={[
                                {label: "Redfort Automation Workflow", value: "DWF_RedfortAutomationWorkflow"},
                            ]}
                            selectedAriaLabel="Selected"
                        />
                    </FormField>
                </div>
            </ColumnLayout>
            <div>
                {(() => {
                    switch (selectedWorkflowType.value) {
                        case 'DWF_RedfortAutomationWorkflow':
                            return <RedfortWorkflowContent/>
                        default:
                            return null
                    }
                })()}
            </div>
        </ColumnLayout>
    );
}