import {ColumnLayout, FormField, Input, Select, SelectProps, Tiles} from "@amzn/awsui-components-react";
import React, {useState} from "react";
import AtochaWorkflowContent from "../workflowUtils/AtochaWorkflowContent";
import PmetworkflowContent from "../workflowUtils/PmetWorkflowContent";

export default function ConfigurableWorkflowPage() {
    const [
        selectedSIMFolder,
        setSelectedSIMFolder
    ] = useState<SelectProps.Option>({label: "PAMS", value: "PAMS"});

    const [
        selectedWorkflowType,
        setSelectedWorkflowType
    ] = useState<SelectProps.Option>({});

    const [serviceName, setServiceName] = useState("");
    const [friendlyServiceName, setFriendlyServiceName] = useState("");

    return (
        <ColumnLayout borders="horizontal">
            <h2>Create Configurable Workflows</h2>
            <ColumnLayout columns={4}>
                <div>
                    <FormField
                        label="Choose SIM Folder"
                    >
                        <Select
                            selectedOption={selectedSIMFolder}
                            onChange={({detail}) =>
                                setSelectedSIMFolder(detail.selectedOption)
                            }
                            options={[
                                {label: "PAMS", value: "PAMS"},
                                {label: "PIDS", value: "PIDS"},
                            ]}
                            selectedAriaLabel="Selected"
                        />
                    </FormField>
                </div>
                <div>
                    <FormField
                        label="Service Name"
                    >
                        <Input value={serviceName} onChange={event =>
                            setServiceName(event.detail.value)
                        }/>
                    </FormField>
                </div>
                <div>
                    <FormField
                        label="Friendly / Other Service Name"
                    >
                        <Input value={friendlyServiceName} onChange={event =>
                            setFriendlyServiceName(event.detail.value)
                        }/>
                    </FormField>
                </div>
                <div>
                    <FormField
                        label="Choose Workflow Type"
                    >
                        <Select
                            selectedOption={selectedWorkflowType}
                            onChange={({detail}) =>
                                setSelectedWorkflowType(detail.selectedOption)
                            }
                            // Value in this dropdown should be Workflow type constant in Workflow_Trigger_Mapping_Store table
                            options={[
                                {label: "Atocha Query Aggregation", value: "DWF_ATOCHA_WORKFLOW"},
                                {label: "PMET Query Aggregation", value: "DWF_METRICS_GATHERER3_0"},
                            ]}
                            selectedAriaLabel="Selected"
                        />
                    </FormField>
                </div>
            </ColumnLayout>
            <div>
                {(() => {
                    switch (selectedWorkflowType.value) {
                        case 'DWF_ATOCHA_WORKFLOW':
                            return <AtochaWorkflowContent/>
                        case 'DWF_METRICS_GATHERER3_0':
                            return <PmetworkflowContent/>
                        default:
                            return null
                    }
                })()}
            </div>
        </ColumnLayout>
    );
}