import PageHeader from './PageHeader';
import {
    Box,
    Button,
    ColumnLayout,
    FormField,
    Grid,
    Input,
    Modal,
    SpaceBetween, Wizard
} from "@amzn/awsui-components-react";
import HomeContent from "./HomeContent";
import React from "react";

export default function Home() {
    const [onboardModalView, setOnboardModalView] = React.useState(false);
    const [
        activeStepIndex,
        setActiveStepIndex
    ] = React.useState(0);
    return (
        <div>
            <Modal size="large"
                   onDismiss={() => setOnboardModalView(false)}
                   visible={onboardModalView}
                   closeAriaLabel="Close modal"
                   header="Steps to Onboard to DEM Workflow"
            >
                <Wizard
                    i18nStrings={{
                        stepNumberLabel: stepNumber =>
                            `Step ${stepNumber}`,
                        collapsedStepsLabel: (stepNumber, stepsCount) =>
                            `Step ${stepNumber} of ${stepsCount}`,
                        skipToButtonLabel: (step, stepNumber) =>
                            `Skip to ${step.title}`,
                        navigationAriaLabel: "Steps",
                        cancelButton: "Cancel",
                        previousButton: "Previous",
                        nextButton: "Next",
                        submitButton: "Onboard to DEM",
                        optional: "optional"
                    }}
                    onNavigate={({detail}) =>
                        setActiveStepIndex(detail.requestedStepIndex)
                    }
                    activeStepIndex={activeStepIndex}
                    steps={[
                        {
                            title: "Provide SIM Folder ID",
                            description:
                                "Each resolver group / Sim folder has 36 character alphanumeric id which is needed by DEM Workflow to read and update tickets",
                            content: (
                                <FormField label="SIM Folder ID">
                                    <Input value="" onChange={event => {
                                    }}/>
                                </FormField>
                            )
                        },
                        {
                            title: "Add DEM as Watcher",
                            content: (
                                <p>
                                    Add below SQS as Watcher to SIM Folder given in previous step
                                    <h3>SQS Queue</h3>
                                </p>
                            ),
                        },
                        {
                            title: "Pre-requisites",
                            content: (
                                <p>
                                    Follow Pre-requisites steps mentioned in below link
                                    <br/>
                                    <br/>
                                    <a href="https://w.amazon.com/bin/view/Alexa/Excellence/Availability_and_Monitoring/Projects/DEM/OnBoarding#HPrerequisite"
                                       target="_blank">DEM Onboarding Prerequisites</a>
                                </p>
                            ),
                        }
                    ]}
                />
            </Modal>
            <PageHeader/>
            <div style={{background: "rgba(10,105,161,255)", padding: 7, height: 150}}>
                <Box textAlign="center" margin={{left: "xl", bottom: "xl", right: "xl"}}
                     padding={{left: "xl", bottom: "xl", right: "xl"}}>
                    <Grid
                        gridDefinition={[{colspan: 3}, {colspan: 6}, {colspan: 3}]}
                    >
                        <div></div>
                        <div>
                            <ColumnLayout columns={2}>
                                <div style={{textAlign: "center", color: "white"}}>
                                    <h1 style={{textAlign: "left"}}>Alexa DEM Workflow UI</h1>
                                    <p style={{textAlign: "left"}}>Create powerful Workflows to automate their Event
                                        mitigation strategies or Run-Books</p>
                                </div>
                                <div style={{textAlign: "right", paddingTop: "15px"}}>
                                    <Button onClick={e => {
                                        setOnboardModalView(true);
                                    }} variant="primary">
                                        Onboard to DEM
                                    </Button>
                                </div>
                            </ColumnLayout>
                        </div>
                        <div></div>
                    </Grid>
                </Box>
            </div>
            <div>
                <HomeContent/>
            </div>

        </div>
    );
}
