import {
    Button, ButtonDropdown,
    ColumnLayout,
    Container,
    FormField,
    Header, Input,
    SpaceBetween,
    TagEditor, Textarea,
    Wizard
} from "@amzn/awsui-components-react";
import React, {useState} from "react";
import {MonitorSchema, WorkflowCondition} from "../../dem-api/generated-src";

export default function PmetworkflowContent() {
    const [workflowconditions, setWorkflowconditions] = useState<WorkflowCondition[]>([{
        field: "severity",
        fieldtype: "number",
        condition: "is_less_than",
        value: "5",
    }]);
    const [monitorSearchSchemas, setMonitorSearchSchemas] = useState<MonitorSchema[]>([])

    const [
        activeStepIndex,
        setActiveStepIndex
    ] = React.useState(0);


    function addcondition(id: string) {
        workflowconditions.push({
            conditionType: id
        })
        setWorkflowconditions([...workflowconditions]);
    }

    function addSchema(id: string) {
        monitorSearchSchemas.push({
            schema: id
        })
        setMonitorSearchSchemas([...monitorSearchSchemas]);
        console.log(monitorSearchSchemas)
    }

    return (
        <div>
            <br/>
            <Container header={
                <Header
                    variant="h3"
                >
                    <b>PMET Query Aggregation Workflow Configuration</b>
                </Header>
            }>
                <Wizard
                    i18nStrings={{
                        stepNumberLabel: stepNumber =>
                            `Section ${stepNumber}`,
                        collapsedStepsLabel: (stepNumber, stepsCount) =>
                            `Section ${stepNumber} of ${stepsCount}`,
                        skipToButtonLabel: (step, stepNumber) =>
                            `Skip to ${step.title}`,
                        navigationAriaLabel: "Sections",
                        cancelButton: "Cancel",
                        previousButton: "Previous",
                        nextButton: "Next",
                        submitButton: "Submit Changes",
                        optional: "optional"
                    }}
                    onNavigate={({detail}) =>
                        setActiveStepIndex(detail.requestedStepIndex)
                    }
                    activeStepIndex={activeStepIndex}
                    steps={[
                        {
                            title: "Workflow Information",
                            content: (
                                <p>
                                    <b style={{color: "darkgray"}}>Target Workflow</b> : DWF_METRICS_GATHERER3_0
                                </p>
                            ),
                        },
                        {
                            title: "Add PMET Search Params",
                            description:
                                "",
                            content: (
                                <div>
                                    <SpaceBetween size="l" direction="vertical">
                                        {monitorSearchSchemas.map(schema => {
                                            if (schema.schema == 'Service') {
                                                return <div>
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            height: "10px",
                                                            borderBottom: "1px solid lightgray",
                                                            textAlign: "center"
                                                        }}>
                                                                    <span style={{
                                                                        fontSize: "15px",
                                                                        fontStyle: "italic",
                                                                        backgroundColor: "white",
                                                                        padding: "30px",
                                                                        color: "gray"
                                                                    }}><b>Schema Type :</b> {schema.schema}</span><br/>
                                                    </div>
                                                    <br/>
                                                    <ColumnLayout columns={4}>
                                                        <div >
                                                            <FormField label="DataSet">
                                                                <Input value={schema.dataset ? schema.dataset : ""}
                                                                       onChange={event =>
                                                                           schema.dataset = event.detail.value
                                                                       }/>
                                                            </FormField>
                                                        </div>
                                                        <div>
                                                            <FormField label="Marketplace">
                                                                <Input
                                                                    value={schema.marketplace ? schema.marketplace : ""}
                                                                    onChange={event =>
                                                                        schema.marketplace = event.detail.value
                                                                    }/>
                                                            </FormField>
                                                        </div>
                                                        <div>
                                                            <FormField label="HostGroup">
                                                                <Input value={schema.hostgroup ? schema.hostgroup : ""}
                                                                       onChange={event =>
                                                                           schema.hostgroup = event.detail.value
                                                                       }/>
                                                            </FormField>
                                                        </div>
                                                        <div>
                                                            <FormField label="Host">
                                                                <Input value={schema.host ? schema.host : ""}
                                                                       onChange={event =>
                                                                           schema.host = event.detail.value
                                                                       }/>
                                                            </FormField>
                                                        </div>
                                                        <div>
                                                            <FormField label="Service Name">
                                                                <Input value={schema.service ? schema.service : ""}
                                                                       onChange={event =>
                                                                           schema.service = event.detail.value
                                                                       }/>
                                                            </FormField>
                                                        </div>
                                                        <div>
                                                            <FormField label="Method Name">
                                                                <Input value={schema.method ? schema.method : ""}
                                                                       onChange={event =>
                                                                           schema.method = event.detail.value
                                                                       }/>
                                                            </FormField>
                                                        </div>
                                                        <div>
                                                            <FormField label="Client">
                                                                <Input value={schema.client ? schema.client : ""}
                                                                       onChange={event =>
                                                                           schema.client = event.detail.value
                                                                       }/>
                                                            </FormField>
                                                        </div>
                                                        <div>
                                                            <FormField label="Metric Class">
                                                                <Input
                                                                    value={schema.metricclass ? schema.metricclass : ""}
                                                                    onChange={event =>
                                                                        schema.metricclass = event.detail.value
                                                                    }/>
                                                            </FormField>
                                                        </div>
                                                        <div>
                                                            <FormField label="Instance">
                                                                <Input value={schema.instance ? schema.instance : ""}
                                                                       onChange={event =>
                                                                           schema.instance = event.detail.value
                                                                       }/>
                                                            </FormField>
                                                        </div>
                                                        <div>
                                                            <FormField label="Metric">
                                                                <Input value={schema.metric ? schema.metric : ""}
                                                                       onChange={event =>
                                                                           schema.metric = event.detail.value
                                                                       }/>
                                                            </FormField>
                                                        </div>
                                                    </ColumnLayout>
                                                </div>
                                            } else if (schema.schema == 'Search') {
                                                return <div>
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            height: "10px",
                                                            borderBottom: "1px solid lightgray",
                                                            textAlign: "center"
                                                        }}>
                                                                    <span style={{
                                                                        fontSize: "15px",
                                                                        fontStyle: "italic",
                                                                        backgroundColor: "white",
                                                                        padding: "30px",
                                                                        color: "gray"
                                                                    }}><b>Schema Type :</b> {schema.schema}</span><br/>
                                                    </div>
                                                    <br/>
                                                    <div>
                                                        <FormField label="Pattern">
                                                            <Textarea
                                                                onChange={({detail}) => {
                                                                    schema.pattern = detail.value
                                                                }}
                                                                value={schema.pattern ? schema.pattern : ""}
                                                                placeholder="Add Search Pattern here"
                                                            />
                                                        </FormField>
                                                    </div>

                                                </div>
                                            }
                                        })}
                                        <ButtonDropdown
                                            items={[
                                                {text: "Service", id: "Service", disabled: false},
                                                {text: "Search", id: "Search", disabled: false},
                                            ]}

                                            onItemClick={event => {
                                                addSchema(event.detail.id)
                                            }}
                                        >
                                            Add Schema
                                        </ButtonDropdown>
                                    </SpaceBetween>

                                </div>
                            )
                        },
                        {
                            title: "Add Conditions",
                            content: (
                                <div>
                                    <SpaceBetween size="l" direction="vertical">
                                        {workflowconditions.map(condition => {
                                                return <div>
                                                    {condition.conditionType ?
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                height: "10px",
                                                                borderBottom: "1px solid lightgray",
                                                                textAlign: "center"
                                                            }}>
                                            <span style={{
                                                fontSize: "15px", fontStyle: "italic",
                                                backgroundColor: "white", padding: "30px", color: "gray"
                                            }}>{condition.conditionType}</span><br/></div> : ""}
                                                    <br/>
                                                    <ColumnLayout columns={5}>
                                                        <div>
                                                            <FormField label="Field">
                                                                <Input value={condition.field ? condition.field : ""}
                                                                       onChange={event =>
                                                                           condition.field = event.detail.value
                                                                       }/>
                                                            </FormField>
                                                        </div>
                                                        <div>
                                                            <FormField label="Field Type">
                                                                <Input
                                                                    value={condition.fieldtype ? condition.fieldtype : ""}
                                                                    onChange={event =>
                                                                        condition.fieldtype = event.detail.value
                                                                    }/>
                                                            </FormField>
                                                        </div>
                                                        <div>
                                                            <FormField label="Condition">
                                                                <Input
                                                                    value={condition.condition ? condition.condition : ""}
                                                                    onChange={event =>
                                                                        condition.condition = event.detail.value
                                                                    }/>
                                                            </FormField>
                                                        </div>
                                                        <div>
                                                            <FormField label="Value">
                                                                <Input value={condition.value ? condition.value : ""}
                                                                       onChange={event =>
                                                                           condition.value = event.detail.value
                                                                       }/>
                                                            </FormField>
                                                        </div>
                                                        <div style={{paddingTop: 24}}>
                                                            <Button>Remove</Button>
                                                        </div>
                                                    </ColumnLayout>
                                                </div>
                                            }
                                        )}
                                        <ButtonDropdown
                                            items={[
                                                {text: "AND", id: "and", disabled: false},
                                                {text: "OR", id: "or", disabled: false},
                                                {text: "NOT", id: "not", disabled: false},
                                            ]}

                                            onItemClick={event => {
                                                addcondition(event.detail.id)
                                            }}
                                        >
                                            Add Condition
                                        </ButtonDropdown>
                                    </SpaceBetween>
                                </div>
                            ),
                        }
                    ]}
                />
            </Container>
        </div>
    );
}