import React, {useState} from "react";
import {
    Button, ButtonDropdown,
    ColumnLayout,
    Container,
    FormField,
    Header,
    Input, SpaceBetween,
    TagEditor,
    Wizard
} from "@amzn/awsui-components-react";
import {WorkflowCondition} from "../../dem-api/generated-src";

export default function RedfortWorkflowContent() {
    const [workflowconditions, setWorkflowconditions] = useState<WorkflowCondition[]>([{
        field: "severity",
        fieldtype: "number",
        condition: "is_less_than",
        value: "5",
    }]);
    const [
        activeStepIndex,
        setActiveStepIndex
    ] = React.useState(0);


    function addcondition(id: string) {
        workflowconditions.push({
            conditionType: id
        })
        setWorkflowconditions([...workflowconditions]);
    }

    return (
        <div>
            <br/>
            <Container header={
                <Header
                    variant="h3"
                >
                    <b>Redfort Automation Workflow Configuration</b>
                </Header>
            }>
                <Wizard
                    i18nStrings={{
                        stepNumberLabel: stepNumber =>
                            `Section ${stepNumber}`,
                        collapsedStepsLabel: (stepNumber, stepsCount) =>
                            `Section ${stepNumber} of ${stepsCount}`,
                        skipToButtonLabel: (step, stepNumber) =>
                            `Skip to ${step.title}`,
                        navigationAriaLabel: "Sections",
                        cancelButton: "Cancel",
                        previousButton: "Previous",
                        nextButton: "Next",
                        submitButton: "Submit Changes",
                        optional: "optional"
                    }}
                    onNavigate={({detail}) =>
                        setActiveStepIndex(detail.requestedStepIndex)
                    }
                    activeStepIndex={activeStepIndex}
                    steps={[
                        {
                            title: "Workflow Information",
                            content: (
                                <p>
                                    <b style={{color: "darkgray"}}>Target Workflow</b> : DWF_RedfortAutomationWorkflow
                                </p>
                            ),
                        },
                        {
                            title: "Add Conditions",
                            content: (
                                <div>
                                    <SpaceBetween size="l" direction="vertical">
                                        {workflowconditions.map(condition => {
                                                return <div>
                                                    {condition.conditionType ?
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                height: "10px",
                                                                borderBottom: "1px solid lightgray",
                                                                textAlign: "center"
                                                            }}>
                                            <span style={{
                                                fontSize: "15px", fontStyle: "italic",
                                                backgroundColor: "white", padding: "30px", color: "gray"
                                            }}>{condition.conditionType}</span><br/></div> : ""}
                                                    <br/>
                                                    <ColumnLayout columns={5}>
                                                        <div>
                                                            <FormField label="Field">
                                                                <Input value={condition.field ? condition.field : ""}
                                                                       onChange={event =>
                                                                           condition.field = event.detail.value
                                                                       }/>
                                                            </FormField>
                                                        </div>
                                                        <div>
                                                            <FormField label="Field Type">
                                                                <Input
                                                                    value={condition.fieldtype ? condition.fieldtype : ""}
                                                                    onChange={event =>
                                                                        condition.fieldtype = event.detail.value
                                                                    }/>
                                                            </FormField>
                                                        </div>
                                                        <div>
                                                            <FormField label="Condition">
                                                                <Input
                                                                    value={condition.condition ? condition.condition : ""}
                                                                    onChange={event =>
                                                                        condition.condition = event.detail.value
                                                                    }/>
                                                            </FormField>
                                                        </div>
                                                        <div>
                                                            <FormField label="Value">
                                                                <Input value={condition.value ? condition.value : ""}
                                                                       onChange={event =>
                                                                           condition.value = event.detail.value
                                                                       }/>
                                                            </FormField>
                                                        </div>
                                                        <div style={{paddingTop: 24}}>
                                                            <Button>Remove</Button>
                                                        </div>
                                                    </ColumnLayout>
                                                </div>
                                            }
                                        )}
                                        <ButtonDropdown
                                            items={[
                                                {text: "AND", id: "and", disabled: false},
                                                {text: "OR", id: "or", disabled: false},
                                                {text: "NOT", id: "not", disabled: false},
                                            ]}

                                            onItemClick={event => {
                                                addcondition(event.detail.id)
                                            }}
                                        >
                                            Add Condition
                                        </ButtonDropdown>
                                    </SpaceBetween>
                                </div>
                            ),
                        }
                    ]}
                />
            </Container>
        </div>
    );
}