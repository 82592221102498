import {
    Badge,
    Button,
    ColumnLayout,
    Grid,
    Icon,
    Select,
    SelectProps,
    SpaceBetween
} from "@amzn/awsui-components-react";
import React, {useState} from "react";
import StarPicker from "react-star-picker";
import { BsFillBookmarkFill } from "react-icons/bs"

export default function WorkFlowHomePage() {
    const [
        selectedSIMFolder,
        setSelectedSIMFolder
    ] = useState<SelectProps.Option>({label: "PAMS", value: "PAMS"});
    return (
        <div>
            <ColumnLayout borders="horizontal">
                <div style={{width: "500px"}}>
                    <h4>Workflows onboarded to SIM Folder :</h4>
                    <Select
                        selectedOption={selectedSIMFolder}
                        onChange={({detail}) =>
                            setSelectedSIMFolder(detail.selectedOption)
                        }
                        // ADD Folder Id as value during backend implementation
                        options={[
                            {label: "PAMS", value: "PAMS"},
                            {label: "PIDS", value: "PIDS"},
                        ]}
                        selectedAriaLabel="Selected"
                    />
                </div>
                <h2>Workflows</h2>
                <div>
                    <Grid gridDefinition={[{colspan: 0.5}, {colspan: 8}, {colspan: 1.5}]}>
                        <div style={{paddingTop: "4px"}}><BsFillBookmarkFill size={16} color="orange"/></div>
                        <div>

                            <div>
                                <SpaceBetween size="s" direction="horizontal">
                                    <b style={{fontSize: "medium"}}>Redfort Automation</b>
                                    <StarPicker
                                        // @ts-expect-error bad component typing
                                        starRenderer={undefined}
                                        disabled={false}
                                        onChange={(value) => {
                                        }}
                                        value={4}
                                        halfStars={false}
                                        numberStars={5}
                                        size={12}
                                    />(30)
                                </SpaceBetween>
                            </div>
                            <p>This is a subscribable workflow in S&S category, created by AXES team</p>
                            <SpaceBetween size="xs" direction="horizontal">
                                <Badge>Redfort</Badge>
                                <Badge>AXES</Badge>
                            </SpaceBetween>
                        </div>
                        <div>
                            <SpaceBetween size="xs" direction="horizontal">
                                <div style={{padding: "5px"}}>
                                    <Button variant="normal">Unsubscribe <Icon name="notification"
                                                                               variant="normal"/></Button>
                                </div>
                                <div><Button iconName="download" variant="icon"/></div>
                                <div><Button iconName="group-active" variant="icon"/> 197096</div>
                            </SpaceBetween>
                        </div>
                    </Grid>
                </div>
                <div>
                    <Grid gridDefinition={[{colspan: 0.5}, {colspan: 8}, {colspan: 1.5}]}>
                        <div style={{paddingTop: "4px"}}><BsFillBookmarkFill size={16} color="lightSkyBlue"/></div>
                        <div>
                            <div>
                                <SpaceBetween size="s" direction="horizontal">
                                    <b style={{fontSize: "medium"}}>Atocha Aggregation Workflow</b>
                                    <StarPicker
                                        // @ts-expect-error bad component typing
                                        starRenderer={undefined}
                                        disabled={false}
                                        onChange={(value) => {
                                        }}
                                        value={3}
                                        halfStars={false}
                                        numberStars={5}
                                        size={12}
                                    />(12)
                                </SpaceBetween>
                            </div>
                            <p>This is a configurable workflow, created by A&M Team</p>
                            <SpaceBetween size="xs" direction="horizontal">
                                <Badge>Atocha</Badge>
                                <Badge>AXES</Badge>
                                <Badge>logging</Badge>
                                <Badge>query</Badge>
                            </SpaceBetween>
                        </div>
                        <div>
                            <SpaceBetween size="xs" direction="horizontal">
                                <div style={{padding: "5px"}}>
                                    <Button variant="primary">Edit Configuration</Button>
                                </div>
                                <div><Button iconName="download" variant="icon"/></div>
                                <div><Button iconName="group-active" variant="icon"/> 13831</div>
                            </SpaceBetween>
                        </div>
                    </Grid>
                </div>
            </ColumnLayout>
            <br/><br/><br/>
            <ColumnLayout borders="horizontal">
                <h2>Other Workflows you can subscribe to</h2>
                <div>
                    <Grid gridDefinition={[{colspan: 0.5}, {colspan: 8}, {colspan: 1.5}]}>
                        <div style={{paddingTop: "4px"}}><BsFillBookmarkFill size={16} color="orange"/></div>
                        <div>
                            <div>
                                <SpaceBetween size="s" direction="horizontal">
                                    <b style={{fontSize: "medium"}}>Deduped Workflow</b>
                                    <StarPicker
                                        // @ts-expect-error bad component typing
                                        starRenderer={undefined}
                                        disabled={false}
                                        onChange={(value) => {
                                        }}
                                        value={3}
                                        halfStars={false}
                                        numberStars={5}
                                        size={12}
                                    />(22)
                                </SpaceBetween>
                            </div>
                            <p>This is a team only customizable workflow</p>
                            <SpaceBetween size="xs" direction="horizontal">
                                <Badge>Ticket</Badge>
                                <Badge>AXES</Badge>
                                <Badge>de-dupe</Badge>
                            </SpaceBetween>
                        </div>
                        <div>
                            <SpaceBetween size="xs" direction="horizontal">
                                <div style={{padding: "5px"}}>
                                    <Button variant="primary">Subscribe <Icon name="notification"
                                                                              variant="normal"/></Button>
                                </div>
                                <div><Button iconName="download" variant="icon"/></div>
                                <div><Button iconName="group-active" variant="icon"/> 1032</div>
                            </SpaceBetween>
                        </div>
                    </Grid>
                </div>
            </ColumnLayout>
        </div>
    );
}