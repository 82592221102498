import {ColumnLayout, Grid} from "@amzn/awsui-components-react";
import React, {useState} from "react";
import WorkFlowHomePage from "../workflows/WorkflowHomePage";
import ConfigurableWorkflowPage from "../workflows/ConfigurableWorkflowPage";
import SubscribableWorkflowPage from "../workflows/SubscribableWorkflowPage";
import CustomizableWorkflowPage from "../workflows/CustomizableWorkflowPage";
import TasksPage from "../tasks/TasksPage";
import {BsFillBookmarkFill} from "react-icons/bs";

export default function HomeContent() {
    const [viewOption, setViewOption] = useState("WorkFlowHome");

    return (
        <Grid
            gridDefinition={[{colspan: 2}, {colspan: 8}]}
        >
            <div style={{padding: "20px"}}>
                <ColumnLayout borders="horizontal">
                    <div>
                        <a href={"#"} style={{
                            color: (viewOption == "WorkFlowHome") ? "darkorange" : "black",
                            textDecoration: "none"
                        }} onClick={e => {
                            setViewOption("WorkFlowHome")
                        }}><h3>Workflows</h3></a>
                        <div style={{paddingLeft: "15px"}}>
                            <ColumnLayout borders="horizontal">
                                <div><a href={"#"} style={{
                                    color: (viewOption == "ConfigWorkFlow") ? "darkorange" : "black",
                                    textDecoration: "none"
                                }} onClick={e => {
                                    setViewOption("ConfigWorkFlow")
                                }}><b><BsFillBookmarkFill size={12} color="lightSkyBlue"/> Configurable Workflow</b></a></div>
                                <div><a href={"#"} style={{
                                    color: (viewOption == "SubsWorkFlow") ? "darkorange" : "black",
                                    textDecoration: "none"
                                }} onClick={e => {
                                    setViewOption("SubsWorkFlow")
                                }}><b><BsFillBookmarkFill size={12} color="orange"/> Subscribable Workflow</b></a></div>
                                <div><a href={"#"} style={{
                                    color: (viewOption == "CustWorkFlow") ? "darkorange" : "black",
                                    textDecoration: "none"
                                }} onClick={e => {
                                    setViewOption("CustWorkFlow")
                                }}><b><BsFillBookmarkFill size={12} color="gray"/> Customizable Workflow</b></a></div>
                            </ColumnLayout>
                        </div>
                    </div>
                    <div>
                        <a href={"#"} style={{
                            color: (viewOption == "Tasks") ? "darkorange" : "black",
                            textDecoration: "none"
                        }} onClick={e => {
                            setViewOption("Tasks")
                        }}><h3>Tasks</h3></a>
                    </div>
                </ColumnLayout>
            </div>
            <div style={{padding: "20px"}}>
                {(() => {
                    switch (viewOption) {
                        case 'WorkFlowHome':
                            return <WorkFlowHomePage/>
                        case 'ConfigWorkFlow':
                            return <ConfigurableWorkflowPage/>
                        case 'SubsWorkFlow':
                            return <SubscribableWorkflowPage/>
                        case 'CustWorkFlow':
                            return <CustomizableWorkflowPage/>
                        case 'Tasks':
                            return <TasksPage/>
                        default:
                            return null
                    }
                })()}
            </div>
        </Grid>
    );
}