import {TopNavigation} from "@amzn/awsui-components-react";

export default function PageHeader(){
    return (
        <TopNavigation
            identity={{
                href: "#",
                title: "Alexa DEM Workflow",
            }}
            utilities={[

                {
                    type: "button",
                    text: "Customer Name",
                    iconName: "user-profile",
                }
            ]}
            i18nStrings={{
                searchIconAriaLabel: "Search",
                searchDismissIconAriaLabel: "Close search",
                overflowMenuTriggerText: "More",
                overflowMenuTitleText: "All",
                overflowMenuBackIconAriaLabel: "Back",
                overflowMenuDismissIconAriaLabel: "Close menu"
            }}
        />


    );
}