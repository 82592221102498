import React, {useEffect, useState} from "react";
import {
    Button, ButtonDropdown,
    ColumnLayout,
    Container,
    FormField,
    Header,
    Input, SpaceBetween,
    TagEditor,
    Wizard
} from "@amzn/awsui-components-react";
import {TagEditorProps} from "../../../build/private/tmp/brazil-path/testrun.runtimefarm/aws-ui-npm/components";
import {WorkflowCondition} from "../../dem-api/generated-src";

export default function AtochaWorkflowContent() {
    const [queryParams, setQueryParams] = useState<TagEditorProps.Tag[]>([]);
    const [workflowconditions, setWorkflowconditions] = useState<WorkflowCondition[]>([{
        field: "severity",
        fieldtype: "number",
        condition: "is_less_than",
        value: "5",
    }]);
    const [
        activeStepIndex,
        setActiveStepIndex
    ] = React.useState(0);


    function addcondition(id: string) {
        workflowconditions.push({
            conditionType: id
        })
        setWorkflowconditions([...workflowconditions]);
    }

    return (
        <div>
            <br/>
            <Container header={
                <Header
                    variant="h3"
                >
                    <b>Atocha Query Aggregation Workflow Configuration</b>
                </Header>
            }>
                <Wizard
                    i18nStrings={{
                        stepNumberLabel: stepNumber =>
                            `Section ${stepNumber}`,
                        collapsedStepsLabel: (stepNumber, stepsCount) =>
                            `Section ${stepNumber} of ${stepsCount}`,
                        skipToButtonLabel: (step, stepNumber) =>
                            `Skip to ${step.title}`,
                        navigationAriaLabel: "Sections",
                        cancelButton: "Cancel",
                        previousButton: "Previous",
                        nextButton: "Next",
                        submitButton: "Submit Changes",
                        optional: "optional"
                    }}
                    onNavigate={({detail}) =>
                        setActiveStepIndex(detail.requestedStepIndex)
                    }
                    activeStepIndex={activeStepIndex}
                    steps={[
                        {
                            title: "Workflow Information",
                            content: (
                                <p>
                                    <b style={{color: "darkgray"}}>Target Workflow</b> : DWF_ATOCHA_WORKFLOW
                                </p>
                            ),
                        },
                        {
                            title: "Add Atocha Query Params",
                            description:
                                "",
                            content: (
                                <TagEditor
                                    i18nStrings={{
                                        keyPlaceholder: "Enter param",
                                        valuePlaceholder: "Enter value",
                                        addButton: "Add new param",
                                        removeButton: "Remove",
                                        undoButton: "Undo",
                                        undoPrompt:
                                            "This param will be removed upon saving changes",
                                        loading:
                                            "Loading params that are associated with this resource",
                                        keyHeader: "Query Param",
                                        valueHeader: "Value",
                                        optional: "",
                                        keySuggestion: "",
                                        valueSuggestion: "",
                                        emptyTags:
                                            "",
                                        tooManyKeysSuggestion:
                                            "You have more params than can be displayed",
                                        tooManyValuesSuggestion:
                                            "You have more values than can be displayed",
                                        keysSuggestionLoading: "Loading Query Params",
                                        keysSuggestionError:
                                            "Query Keys could not be retrieved",
                                        valuesSuggestionLoading: "Loading Query values",
                                        valuesSuggestionError:
                                            "Query values could not be retrieved",
                                        emptyKeyError: "You must specify a Query param",
                                        maxKeyCharLengthError:
                                            "The maximum number of characters you can use in a tag key is 128.",
                                        maxValueCharLengthError:
                                            "The maximum number of characters you can use in a tag value is 256.",
                                        duplicateKeyError:
                                            "You must specify a unique query param.",
                                        invalidKeyError:
                                            "Invalid param. Params can only contain Unicode letters, digits, white space and any of the following: _.:/=+@-",
                                        invalidValueError:
                                            "Invalid value. Values can only contain Unicode letters, digits, white space and any of the following: _.:/=+@-",
                                        awsPrefixError: "Cannot start with aws:",
                                        tagLimit: (availableTags, tagLimit) =>
                                            availableTags === tagLimit
                                                ? "You can add up to " + tagLimit + " params."
                                                : availableTags === 1
                                                    ? "You can add up to 1 more param."
                                                    : "You can add up to " +
                                                    availableTags +
                                                    " more params.",
                                        tagLimitReached: tagLimit =>
                                            tagLimit === 1
                                                ? "You have reached the limit of 1 param."
                                                : "You have reached the limit of " +
                                                tagLimit +
                                                " params.",
                                        tagLimitExceeded: tagLimit =>
                                            tagLimit === 1
                                                ? "You have exceeded the limit of 1 param."
                                                : "You have exceeded the limit of " +
                                                tagLimit +
                                                " params.",
                                        enteredKeyLabel: key => 'Use "' + key + '"',
                                        enteredValueLabel: value => 'Use "' + value + '"'
                                    }}
                                    tags={queryParams}
                                    onChange={({detail}) => {
                                        const tags = detail.tags as TagEditorProps.Tag[];
                                        setQueryParams(tags)
                                    }
                                    }

                                    keysRequest={() =>
                                        Promise.resolve([
                                            "api",
                                            "apolloEnv",
                                            "apolloStage",
                                            "exceptionSignature",
                                        ])
                                    }
                                />
                            )
                        },
                        {
                            title: "Add Conditions",
                            content: (
                                <div>
                                    <SpaceBetween size="l" direction="vertical">
                                        {workflowconditions.map(condition => {
                                                return <div>
                                                    {condition.conditionType ?
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                height: "10px",
                                                                borderBottom: "1px solid lightgray",
                                                                textAlign: "center"
                                                            }}>
                                            <span style={{
                                                fontSize: "15px", fontStyle: "italic",
                                                backgroundColor: "white", padding: "30px", color: "gray"
                                            }}>{condition.conditionType}</span><br/></div> : ""}
                                                    <br/>
                                                    <ColumnLayout columns={5}>
                                                        <div>
                                                            <FormField label="Field">
                                                                <Input value={condition.field ? condition.field : ""}
                                                                       onChange={event =>
                                                                           condition.field = event.detail.value
                                                                       }/>
                                                            </FormField>
                                                        </div>
                                                        <div>
                                                            <FormField label="Field Type">
                                                                <Input
                                                                    value={condition.fieldtype ? condition.fieldtype : ""}
                                                                    onChange={event =>
                                                                        condition.fieldtype = event.detail.value
                                                                    }/>
                                                            </FormField>
                                                        </div>
                                                        <div>
                                                            <FormField label="Condition">
                                                                <Input
                                                                    value={condition.condition ? condition.condition : ""}
                                                                    onChange={event =>
                                                                        condition.condition = event.detail.value
                                                                    }/>
                                                            </FormField>
                                                        </div>
                                                        <div>
                                                            <FormField label="Value">
                                                                <Input value={condition.value ? condition.value : ""}
                                                                       onChange={event =>
                                                                           condition.value = event.detail.value
                                                                       }/>
                                                            </FormField>
                                                        </div>
                                                        <div style={{paddingTop: 24}}>
                                                            <Button>Remove</Button>
                                                        </div>
                                                    </ColumnLayout>
                                                </div>
                                            }
                                        )}
                                        <ButtonDropdown
                                            items={[
                                                {text: "AND", id: "and", disabled: false},
                                                {text: "OR", id: "or", disabled: false},
                                                {text: "NOT", id: "not", disabled: false},
                                            ]}

                                            onItemClick={event => {
                                                addcondition(event.detail.id)
                                            }}
                                        >
                                            Add Condition
                                        </ButtonDropdown>
                                    </SpaceBetween>
                                </div>
                            ),
                        }
                    ]}
                />
            </Container>
        </div>
    );
}